import { Component } from '@angular/core';
import { SideMenuItem } from './side-menu-item/side-menu-item.component';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  constructor(   protected readonly keycloak: KeycloakService){ }
  sideMenuItems = SIDE_MENU_ITEMS;
  logoutItem: SideMenuItem = {
    title: 'Logout',
    icon: '../../assets/icons/logout.png',
    iconType: 'file',
    route:'/'
  }

  logout(){
    this.keycloak.logout()
  }
}



export const SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'dashboard.png',
    iconType: 'file',
    route: '/dashboard',
  },
  {
    title: 'Stores',
    icon: 'store.png',
    iconType: 'file',
    route: '/store',
  },
  {
    title: 'Products',
    icon: 'item.png',
    iconType: 'file',
    route: '/product',
  },
  {
    title: 'Inventory',
    icon: 'item.png',
    iconType: 'file',
    route: '/inventory',
  },
  {
    title: 'Transactions',
    icon: 'card.png',
    iconType: 'file',
    route: '/transactions',
  },
  {
    title: 'Purchase Orders',
    icon: 'note.png',
    iconType: 'file',
    route: '/purchase-order',
  },
  {
    title: 'Suppliers',
    icon: 'dashboard.png',
    iconType: 'file',
    route: '/supplier',
  },
];
